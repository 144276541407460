.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #202731;
}
#about {
  color: white;
  background-color: #202731;
}
#contact{
  background-color: antiquewhite;
}
#projects{
  background-color: antiquewhite;
  /* background-color: #d35d5d; */
}
#aboutme{
  background-color: antiquewhite;
}
#blogs{
  background-color: antiquewhite;
}
#home{
  background-color: antiquewhite;
}
#testimonials{
  background-color: antiquewhite;
}
#projectdetail{
  background-color: antiquewhite;
}
#navba{
  background-color: #333942;
  color: white;
  padding-left: 5%;
}
#gif{
  width: 70%;
}
.dev {
  color:lemonchiffon;

}
.brick{
  color: #d35d5d;
}
/* #navba{
  background-color: #d8a464;
} */
.custom-shape-divider-bottom-1650314778 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1650314778 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom-1650314778 .shape-fill {
  fill: antiquewhite;
}
.proj-bcg {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.proj-bcg svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.proj-bcg .shape-fill {
  fill: #202731;
}

